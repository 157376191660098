import React from 'react'
import { Container, Box, Stack, Typography, Link, Paper, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export function FAQ() {
  return (
    <>
      <Container sx={{
        mb: '4vh',
        flexGrow: 1,
      }}>
        <Typography variant="h3">
          Frequently Asked Questions
        </Typography>
      </Container>
      <ControlledAccordions />
    </>
  )
}


function ControlledAccordions() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        sx={{ backgroundColor: '#00000033' }}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography fontWeight={'bold'}>
            What is the Echo app?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Echo is a unique mobile application that offers the experience of communicating with a diverse range of bots powered by artificial intelligence. We have various categories of bots, including virtual personalities with their own backstories and characters, expert bots providing advice and knowledge in their fields, and task-oriented bots for specific needs like writing emails or social media posts.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{ backgroundColor: '#00000033' }}
        expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography fontWeight={'bold'}>
            How do I get started with Echo?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Getting started is easy! Just download the Echo app from your mobile store, create an account, and start exploring our different categories of bots. Whether you're looking for advice, companionship, or task assistance, Echo has a bot for you.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{ backgroundColor: '#00000033' }}
        expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography fontWeight={'bold'}>
            How do I contact Echo for support or other questions?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            If you have any questions or need assistance, please feel free to reach out to us at <Link
              color="inherit"
              href="mailto:contact@echoai.in"
              target="_blank"
              rel="noopener noreferrer">
              contact@echoai.in
            </Link>. Our team is always ready to help you with any queries or feedback you may have.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{ backgroundColor: '#00000033' }}
        expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography fontWeight={'bold'}>How can I delete my account and associated data?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            If you decide to delete your account and all associated data, you can easily do so within the app. Go to the settings section and find the 'Delete Account' option.
            Following the prompts there will allow you to permanently remove your account and all related data.
            If you need any assistance or prefer to have us handle this process, please email us at <Link
              color="inherit"
              href="mailto:contact@echoai.in"
              target="_blank"
              rel="noopener noreferrer">
              contact@echoai.in
            </Link>, and our support team will assist you in the deletion process.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* <Accordion
        sx={{ backgroundColor: '#00000033' }}
        expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography fontWeight={'bold'}>What is the answer to "The Ultimate Question of Life, the Universe, and Everything"?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To find the answer, you might want to have a chat with our AI bots – they're quite knowledgeable and might offer some intriguing perspectives.
            Or, as Douglas Adams humorously suggested, the answer is Echo.
            Why not ask our bots and see what they have to say?
          </Typography>
        </AccordionDetails>
      </Accordion> */}
    </div>
  );
}

export default FAQ